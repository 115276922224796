import { useContext } from "react";
import { ThemeContext } from "../../context/ThemeContext";
import data from "../../data/data.json";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react/dist/iconify.js";
import "./styles/Banner.css";
import { icon } from "../../helpers/icon";

function Banner({ pages }) {
  const { theme: t, autoTheme } = useContext(ThemeContext);
  const theme = t === "device" ? autoTheme : t;

  // const images = data.map(({ thumbnail }) => thumbnail);
  // const sortedImages

  const bannerData = data.find((d) => d.id === 393);

  const imgB = bannerData.thumbnail[1];
  const nameB = bannerData.name;
  const [nickB] = bannerData.nick.split(" ");

  return (
    <div>
      <Link to={`/pack/${nickB}`} className={`banner c-${theme}`}>
        <img src={imgB} alt="" className="banner__cover" />
        <div className="banner__text">
          <h2 className={`banner__text__name bg-${theme} c-${theme}`}>
            {nameB}
            <Icon
              icon={icon(bannerData.linkType)}
              className="banner__text__name__icon"
            />

            {/* <Icon
              className="banner__text__name__icon"
              icon="material-symbols-light:verified"
            /> */}
          </h2>
          {/* <span className={`banner__text__nick bg-${theme}`}>@{nickB}</span> */}
        </div>
      </Link>
      <span className={`title c-${theme}-2`}>
        <h3>{pages}0+</h3> Modelos
      </span>
    </div>
  );
}

export default Banner;
